import React from 'react'
import Link from "../utils/link"
import { ArrowRight } from "./icons"
import HyperionLink from "../utils/hyperion-link"

const Footer = (props) => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__top'>
              <p>EMPOWERING REAL ESTATE LEADERS & TEAMS TO DRIVE TRANSFORMATIVE RESULTS</p>
              <p>Real Estate Mentor</p>

              <div className='footer__links'>
                <Link to={`tel:+61400216776`}>0400 216 776</Link>
                <Link to={`mailto:jess@jessdensley.com`}>jess@jessdensley.com</Link>
                <Link to='https://www.linkedin.com/in/jess-d-783025b7/'>LinkedIn</Link>
              </div>

              <div className='footer__social'>
                <Link to='https://www.instagram.com/theeliteagencycoach/'>Instagram</Link>
                <Link to='https://www.facebook.com/jessdensley.com.au'>Facebook</Link>
              </div>
            </div>

            <div className='footer__middle'>
              <h1>Ready? Let’s <br/> Get started.</h1>
              <HyperionLink className='btn btn-hyperion--white' to='https://calendly.com/jess0230/15-min-call-with-jess'>Book a meeting with jess <ArrowRight color='#ffffff' /></HyperionLink>
            </div>

            <div className='footer__bottom'>
              <div>
                <Link to='/terms/'>Terms & Disclaimer</Link>
                <Link to='/terms/'>© 2025 Jess Densley</Link>
              </div>
              <div>
                <button onClick={() => scrollToTop()}>Back to top</button>
                <Link to='https://atollon.com.au?utm_source=jessdensley&utm_medium=website&utm_campaign=referrer&utm_term=education'>Design by Atollon</Link>
              </div>
            </div>
          </div>
        </footer>
    </>
  )
}

export default Footer
