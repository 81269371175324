import React from "react"
import Link from "./link"

const HyperionLink = ({to, children, className, tag}) => {
  return (
    <Link to={to} className={className}>
      <span>
        <span>{children}</span>
      </span>
    </Link>
  )
}

export default HyperionLink