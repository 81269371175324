import React from 'react'
import { Logo } from './icons'
import Link from '../utils/link'

const Header = ({pageContext, location}) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [homeScrolled, setHomeScrolled] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [toggle, setToggle] = React.useState(location?.pathname.replace(/\//g, ''));
  const [offCanvasMenu, setOffCanvasMenu] = React.useState({
    subActive: false, menu: [], initial: false,
  })
  const [direction, setDirection] = React.useState('down')

  let props = {
    onClick: () => setOffCanvas(false),
    activeClassName: 'current',
  }

  let toggleProps = {
    onClick: () => setOffCanvas(false), activeClassName: 'active',
  }

  const openOffCanvas = () => {
    setOffCanvas(!offCanvas)
  }

  const closeMenu = () => {
    setOffCanvas(false)
    const _this = document.querySelectorAll('.off-canvas__nav a')
    _this.forEach(el => {
      el.classList.remove('active')
    })
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled, fScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"
    
    // if body has class page-template--home and user has scrolled past 100vh setHomeScrolled to true
    if (document.body.classList.contains('page-template--home')) {
      hhScrolled = curScroll > window.innerHeight
      setHomeScrolled(hhScrolled)
    }

    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    document.querySelectorAll('.header__nav a').forEach(el => el.classList.add('active'))
    setToggle(location?.pathname.replace(/\//g, ''));
  }, [location?.pathname])

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  let headerClass = "header"
  if (scrolled) headerClass += " header--scrolled"
  if (homeScrolled) headerClass += " header--home-scrolled"
  if (offCanvas) headerClass += " header--off-canvas"
  if (sticky) headerClass += " header--sticky"
  if (direction === 'down' && scrolled) headerClass += ' header--up'

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <div className='header__logo'>
            <Link to='/'>
              <Logo />
            </Link>
          </div>
          <nav className='header__nav'>
            <ul>
              <li>
                <Link to='/program/' {...props}>Program</Link>
              </li>
              <li>
                <Link to='/news/' {...props}>Insights</Link>
              </li>
              <li>
                <Link to='/about/' {...props}>About</Link>
              </li>
              <li>
                <Link to='https://members.jessdensley.com/members-2/' {...props} target='_blank'>Members</Link>
              </li>
              <li>
                <Link to='/contact/' {...props}>Contact</Link>
              </li>
            </ul>
          </nav>
          <button className={`header__hamburger ${offCanvas ? 'header__hamburger--active' : ''}`} onClick={openOffCanvas}>
            <span className='lines' />
          </button>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas && "active"}`}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            <ul>
              <li>
                <Link to='/program/' {...props}>Program</Link>
              </li>
              <li>
                <Link to='/news/' {...props}>Insights</Link>
              </li>
              <li>
                <Link to='/about/' {...props}>About</Link>
              </li>
              <li>
                <Link to='https://members.jessdensley.com/members-2/' {...props} target='_blank'>Members</Link>
              </li>
              <li>
                <Link to='/contact/' {...props}>Contact</Link>
              </li>
            </ul>
          </nav>
          <div className='off-canvas__social'>
            <Link to={`tel:0400216776`}>0400 216 776</Link>
            <Link to={`mailto:Support@Jessdensley.com`}>support@Jessdensley.com</Link>
            <Link to='https://www.linkedin.com/in/jess-d-783025b7/'>LinkedIN</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header